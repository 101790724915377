import { WalletConnectButton, WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import ConnectWallBtn from "./connectWalBtn";
import logo from '../images/logo.webp'
import { useState } from "react";
import { useWallet, useWalletMultiButton } from "@solana/wallet-adapter-react";
import CustomButton from "./customSelectWalletBtn";
import nacl from "tweetnacl";
import { bs58 } from "@project-serum/anchor/dist/cjs/utils/bytes";





export default function Header() {
  const {publicKey} = useWallet()
//   const [dappKeyPair] = useState(nacl.box.keyPair());
  
//   const params = new URLSearchParams({
//     dapp_encryption_public_key: bs58.encode(dappKeyPair.publicKey),
//     cluster: "devnet",
//     app_url: "https://solana-phantom.netlify.app/",
//     redirect_link: '',
//   });


  return (
    <header>
      <nav class="border-gray-200 px-4 lg:px-6 py-2.5 bg-transparent z-50 ">
        <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-2xl">
          <a href="#" class="flex items-center">
            <img
              src={logo}
              class="mr-1 h-6 sm:h-9 rounded-lg"
              alt="solana Logo"
            />
            <span class="self-center text-xl font-semibold whitespace-nowrap text-white">
              SolOnFomo
            </span>
          </a>

          {/* `https://phantom.app/ul/v1/connect?${params.toString()}` */}
          <div className="flex items-center ">
            {/* <a className="text-blue-500" href={`https://phantom.app/ul/v1/connect?${params.toString()}`}>Hellow</a> */}
            
            {
              publicKey ?
              <WalletMultiButton  className="" />
              :
              <CustomButton />
            }
          </div>

          
          {/* <ConnectWallBtn /> */}
          {/* <WalletConnectButton className="" disabled={false} /> */}
          
        </div>
      </nav>
    </header>
  );
}
