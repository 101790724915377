import * as anchor from "@project-serum/anchor";


// master:     J981v2uhjnKB8ArtVXBenD4uZVt3kNU8HNPP6hcpTuoe
// addresses:      CPBLbrVraEgiSdLgTJsoTFsyU1S7gSpFPVGgvJEXYjXK
// user:        tok83




const keys = {
    programId: "R959aHu59CDkCcCfQcVexRxKpFCtZxJrGqL28rFWDpU",
    master: new anchor.web3.PublicKey('6YL8CzP1gVyLdy4t5sVkyEkwYMv5qWVFzo6XAxMCtYH7'),
    address24: new anchor.web3.PublicKey('4xXvtwe8qLZLGUdbHmaWNnkeTcts2oozJyWDnWygwUGq'),
    address3: new anchor.web3.PublicKey('3bYzjrW1FXSdT35h2kCeSQbYqJkfi7yDqZDds9G7gd8y'),
    addresses: new anchor.web3.PublicKey('EWsgV47sNm9z3qiT9iF3jfddFLQWdw9iiYQnCqcXFTDr'),
   // url:"/api",
    url:"https://api.solonfomo.com"
     //url:"http://18.118.241.47"
};













export default keys;
