import { AnchorProvider } from "@project-serum/anchor";
import * as anchor from "@project-serum/anchor";

import idl from '../back/idl2.json'

import { useWallet } from "@solana/wallet-adapter-react";
import * as web3 from '@solana/web3.js';
import keys from "../config";



export const fetchAddressesData = async () => {
    
    const connection = new web3.Connection(web3.clusterApiUrl('devnet'), 'confirmed')
    const programId = keys.programId;
    try {
        const provider = new AnchorProvider(connection, 'confirmed');
        const program = new anchor.Program(idl, programId, provider);
        // Initialize the master account address
        // const masterAccountAddress = new anchor.web3.PublicKey(pubkey);
        
        
        const addAccount = await program.account.addresses.fetch(keys.addresses);

        console.log("Address Account Data:");
        console.log('account data addresses ==>', addAccount.addr1);
        return addAccount

    } catch (error) {
        console.error('Error fetching master account data:', error);
    }
}
