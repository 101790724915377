import axios from "axios";

export async function convertSolToUsd(solAmount) {
    try {
        
      const response = await axios.get(
        'https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd'
      );

      console.log("response in usd",response)
      const solPrice = response.data.solana.usd;
      console.log("respons-price-usd",1 * solPrice)
      return solAmount * solPrice;
    } catch (error) {
      console.error('Error fetching the SOL price:', error);
      return null; // Return null or handle the error as needed
    }
  }
  
