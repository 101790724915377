import React, { useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletModal, useWalletModal } from '@solana/wallet-adapter-react-ui';
import { useWalletContext } from '../context/walletContext';
import DisclaimerModal from './disclaimerModal';


const CustomButton = ({ onClick, children }) => {
  const { connect, disconnect, publicKey, wallet, wallets } = useWallet();
  const {} = useWalletModal()
  const {setShowDisclaimer, showDisclaimer, disclaimerAccepted} = useWalletContext()

  const handleClick = () => {
     
    setShowDisclaimer(true)
      
  };

  return (
    <>
    <button onClick={handleClick} className='py-2.5 px-4 bg-neutral-800 rounded-lg text-white'>
      {publicKey ? 'Disconnect Wallet' : 'Connect Wallet'}
      {children}
    </button>
    {
        showDisclaimer ?
        <DisclaimerModal />
        : disclaimerAccepted ? 
        <WalletModal />
        :
        
        <></>
    }
    </>
  );
};

export default CustomButton;
