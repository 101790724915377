import { useAnchorWallet, useWallet } from '@solana/wallet-adapter-react';
import Solflare from '@solflare-wallet/sdk';
import { createContext, useState, useContext, useEffect } from 'react';
import * as web3 from "@solana/web3.js";
import { AnchorProvider } from "@project-serum/anchor";
import * as anchor from "@project-serum/anchor";
import keys from '../config';
import idl from "../back/idl.json";
import axios from 'axios';




const WalletContext = createContext();

export const WalletApi = ({ children }) => {
  const [pubkey, setPubkey] = useState(); // Initial value
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [claimedId, setClaimedId] = useState();
  const [depData, setDepData] = useState();  
  const [averageShare,setAverageShare] = useState()

  const wallet = useAnchorWallet();
  const {publicKey} = useWallet();


  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] =  useState(false)

  const [claimableDpendency, setClaimableDependency] = useState(false)

  

  const [isDepDone, setIsDepDone] = useState(false);

  const connection = new web3.Connection(
    web3.clusterApiUrl("devnet", true),
    "confirmed"
  );

  const provider = new AnchorProvider(
    connection,
    wallet,
    "confirmed"
  );


  const program = new anchor.Program(
    idl,
    keys.programId,
    provider
  );

  
  console.log('keys ', provider);

  const claimModal = async () => {
    
    const url = keys.url + '/depositor/depositor-deposits';
    const apiParams = {
      'wallet_address':wallet?.publicKey
    }
    console.log('claim rferrals called');

    await fetch( url , {
      method:"POST",
      headers:{
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin":"*"
      },
      body: JSON.stringify(apiParams)
    })
    .then(async(resp) => {
      const responseData = await resp.json();
      setDepData(responseData.data)
      console.log('responsed a = ==> ', responseData);
    })
    
    .catch((err) =>{
      console.log(err);
    })
  }

  const fetchAverageShare=async()=>{
    const data = {
      'wallet_address':wallet?.publicKey
    }
    axios.post(`${keys.url}/depositor/user_amount_comparison`,data).then((res)=>{
      console.log("response from fetchapi",res)
      if(res?.data?.status ===200)setAverageShare(res?.data?.comparison)
    }).catch((err)=>{
      console.log("error while fetching average share of user",err)
    })
  }

  useEffect(() => {
      if (wallet?.publicKey){
        claimModal()
        fetchAverageShare()
      }
  }, [publicKey, claimableDpendency, isOpenModal, wallet])
  


  useEffect(()=> {
    if (publicKey) {
      setDisclaimerAccepted(false)
    } 
  }, [publicKey])
  



  return (
    <WalletContext.Provider value={{
      isDepDone, setIsDepDone, claimableDpendency, setClaimableDependency,
       provider, program, connection, disclaimerAccepted,
        setDisclaimerAccepted, setShowDisclaimer, showDisclaimer,
         depData, setDepData, claimedId, setClaimedId, isOpenModal,
          setIsOpenModal, pubkey, setPubkey,averageShare }}>
      {children}
    </WalletContext.Provider>
  );
};


export const useWalletContext = () => useContext(WalletContext);


export default WalletContext;