;

export default function InstructionModal({isInstructModal,setIsInstructModal}) {
    
    
    return (
        <div id="authentication-modal" tabindex="-1" aria-hidden="true" class={isInstructModal ? "flex bg-black/30 backdrop-blur-sm top-0 left-0 overflow-y-auto overflow-x-hidden fixed z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full" : "hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"}>
            <div class="relative p-4 max-w-2xl mt-24 md:mt-0 max-h-full ">
                {/* <!-- Modal content --> */}
                <div class="relative rounded-lg shadow bg-[#0a0a0a] border border-[#353A47]">
                    {/* <!-- Modal header --> */}
                    <div class="flex flex-col  items-center justify-between px-4 pt-4 md:px-5 md:pt-5 rounded-t">
                        <h3 class="text-xl font-semibold text-center w-full uppercase text-white">
                        The main elements of importance are: 
                        </h3>
                        <div>
                        <p className=" mt-2 text-slate-400 px-4 text-sm italic ">1. The timer, which is capped at 24h after deployment and increases by 30sec with each deposit.</p>
                        <p className=" mt-2 text-slate-400 px-4 text-sm italic ">2. The minimum deposit, which increases by 0.00001 SOL after each deposit.</p>
                        <p className=" mt-2 text-slate-400 px-4 text-sm italic ">3. MCap, total amount deposited since inception.</p>
                        <p className=" mt-2 text-slate-400 px-4 text-sm italic ">4. Reward, amount of SOL to be awarded to last the depositor if timer hits zero.</p>
                        <p className=" mt-2 text-slate-400 px-4 text-sm italic ">5. Your share, amount of SOL you have totally deposited.</p>
                        <p className=" mt-2 text-slate-400 px-4 text-sm italic ">6. Your active deposits, amount of deposits from which no claims are made yet.</p>
                        <p className=" mt-2 text-slate-400 px-4 text-sm italic ">7. Claimed earnings, total SOL claimed from protocol.</p>
                        <p className=" mt-2 text-slate-400 px-4 text-sm italic ">8. Claimable earnings, amount of SOL available to be claimed.</p>
                        <p className=" mt-2 text-slate-400 px-4 text-sm italic ">9. Your share as a percentage compared to the average share of all participants.</p>
                        <p className=" mt-2 text-slate-400 px-4 text-sm italic "> 10. Deposit/Redeposit button, press here to deposit new SOL or redeposit claimed SOL.</p>
                        <p className=" mt-2 text-slate-400 px-4 text-sm italic ">11. Claim button, claim whatever amount of SOL currently is claimable.</p>
                        <p className=" mt-2 text-slate-400 px-4 text-sm italic ">12. Stakeholder's claim button, only visible to max 200 stakeholders to claim SOL any moment.</p> 
                        </div>
                        <button onClick={() => setIsInstructModal(false)} type="button" class="end-2.5 text-gray-400 bg-transparent hover:bg-neutral-800 hover:text-neutral-400 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center " data-modal-hide="authentication-modal">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>
                    {/* <!-- Modal body --> */}
                    <div class="px-4 pb-4 md:px-5 md:pb-5 mt-2 w-full">
                    <div className="bg-neutral-800 w-full p-3 max-h-[500px] scrollbar scrollbar-thumb-neutral-900 scrollbar-track-neutral-800   overflow-y-auto rounded-lg flex flex-col gap-y-2">

                       </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}