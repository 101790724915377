import {
    WalletConnectButton,
    WalletModal,
    WalletMultiButton,
} from '@solana/wallet-adapter-react-ui'
import { Connection, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js'
import { useCallback, useEffect, useRef, useState } from 'react'
import '../App.css'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { fetchMasterAccountData } from './masterAccData'
import { BiLogoTelegram } from 'react-icons/bi'
import { FaMedium } from 'react-icons/fa6'

import Counter from './counter'
import Solflare from '@solflare-wallet/sdk'
import DepositBtn from './depositBtn'
import ConnectWallBtn from './connectWalBtn'
import { useWalletContext } from '../context/walletContext'
import ClaimModal from './claimModal'
import CustomButton from './customSelectWalletBtn'
import { fetchAddressesData } from './addressAccountData'
import * as web3 from '@solana/web3.js'
import { AnchorProvider } from '@project-serum/anchor'
import * as anchor from '@project-serum/anchor'
import idl from '../back/idl.json'
import keys from '../config'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import { FaXTwitter } from 'react-icons/fa6'
import { FaRegEye } from 'react-icons/fa'
import InstructionModal from './InstructionModal'
import { convertSolToUsd } from '../helper/helperFunctons'

export default function Hero() {
    const [masterData, setMasterData] = useState()
    const [addressData, setAddressData] = useState()
    // const { connection } = useConnection();
    const { publicKey, wallet, connected } = useWallet()

    const [UserInAddData, setUserInAddData] = useState()
    const [noOfDeposits, setNoOfDeposites] = useState()
    const [userEarnings, setUserEarnings] = useState()
    const [usdBalanceInSol, setUsdBalanceInSol] = useState(0)
    const [yourShare, setYourShare] = useState()
    const [winnerTime, setWinnerTime] = useState(0)
    const [isInstructModal, setIsInstructModal] = useState(false)

    const [isClaimAddrOneProcessing, setIsisClaimAddrOneProcessing] =
        useState(false)

    const {
        setIsOpenModal,
        isOpenModal,
        depData,
        connection,
        program,
        provider,
        isDepDone,
        claimableDpendency,
        averageShare,
    } = useWalletContext()

    const getUserDeposits = async () => {
        const url = keys.url + '/depositor/user_deposits'
        const apiParams = {
            wallet_address: publicKey?.toString(),
        }

        try {
            const response = await axios.post(url, apiParams, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            const responseData = response.data
            setNoOfDeposites(responseData.data)
            console.log('response claim = ==> ', responseData)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    const getUserEarnings = async () => {
        const data = {
            wallet_address: publicKey?.toString(),
        }
        console.log('get user earning data fetch', data)
        axios
            .post(`${keys.url}/depositor/user_earning`, data)
            .then((res) => {
                console.log('get-user-earnings', res)
                if (res?.data?.status === 200)
                    setUserEarnings(res?.data?.data[0].earn)
            })
            .catch((err) => {
                console.log('error', err)
            })
    }

    const getYourShare = async () => {
        const url = keys.url + '/depositor/user_amount_comparison'
        const apiParams = {
            wallet_address: publicKey?.toString(),
        }

        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // "Access-Control-Allow-Origin":"*"
            },
            body: JSON.stringify(apiParams),
        })
            .then(async (resp) => {
                const responseData = await resp.json()
                console.log('response claim share = ==> ', responseData)
                setYourShare(responseData.userTotal)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        fetchTime()
        getUserDeposits()
        getUserEarnings()
        getYourShare()

    }, [isClaimAddrOneProcessing, isOpenModal, wallet, depData])

    useEffect(() => {
        fetchSolBalanceInUsd()
    }, [])

    const fetchSolBalanceInUsd = async () => {
        const balance = await convertSolToUsd(1)
        console.log("balanceinusd", balance)
        setUsdBalanceInSol(balance)
    }

    console.log('is dep data', depData)

    useEffect(() => {
        fetchMasterAccountData().then((data) => {
            console.log('data that is beibg replace', data)
            setMasterData(data)
        })

        fetchAddressesData().then((data) => {
            setAddressData(data.addr1)
            console.log('add array', data.addr1)
        })
    }, [isClaimAddrOneProcessing, isDepDone, claimableDpendency])

    useEffect(() => {
        // Define a function to compare publicKey with an address
        const compareAdd = (pubkey, address) => {
            return address.toString().includes(pubkey.toString())
        }
        console.log('datat-in-effect', publicKey, addressData)

        // Check if publicKey exists
        if (publicKey) {
            // Filter addressData based on compareAdd function
            const userinArray = addressData?.find((el) =>
                compareAdd(publicKey, el.address),
            )

            setUserInAddData(userinArray)

            // Log the filtered array
            console.log('anything in arr =:', userinArray?.claimable.toString())
        }
    }, [publicKey, addressData, isClaimAddrOneProcessing])

    const claimAddrOne = async () => {
        setIsisClaimAddrOneProcessing(true)
        if (!program || !connection)
            throw new Error('Program or connection not initialized')

        console.log(
            'claim add log',
            publicKey.toString(),
            keys.addresses.toString(),
            keys.master.toString(),
        )
        try {
            const claimad = await program.rpc.claimAddressOne({
                accounts: {
                    master: keys.master,
                    addresses: keys.addresses,
                    signer: publicKey,
                    systemProgram: '11111111111111111111111111111111',
                },
            })

            console.log('tex', claimad)
            setIsisClaimAddrOneProcessing(false)
            toast('transaction successful')
        } catch (err) {
            console.log('err', err)
            setIsisClaimAddrOneProcessing(false)
            toast(err)
        }
    }

    //fetch counter time from backend
    const fetchTime = async () => {
        try {
            const data = await axios.get(`${keys.url}/depositor/winner-time`)
            console.log('get winner time', data)
            if (data?.data?.status === 200) {
                setWinnerTime(data?.data?.time)
            }
        } catch (error) {
            console.log('error while getting time data', error)
        }
    }

    function sumClaimableAmount(data) {
        console.log('data', data)

        if (data) {
            let sum = 0
            for (let item of data) {
                let claimedAmount = (
                    item.claimable_amount / LAMPORTS_PER_SOL
                ).toFixed(9)

                sum += parseFloat(claimedAmount)
            }
            return sum
        }
    }

    console.log('mastedata innformation', masterData)

    return (
        <>
            <section
                class={
                    publicKey
                        ? 'lg:h-[100vh] bg-black pt-14 absolute top-0 z-[-1] h-fit w-full flex items-center overflow-x-hidden'
                        : 'bg-black h-[100vh] pt-14 absolute top-0 z-[-1] w-full flex items-center overflow-x-hidden'
                }
            >
                <div className='w-32 h-32 -z-10 bg-[#B4992D] rounded-full absolute top-[0%] 2xl:top-[0%] blur-[80px]'></div>
                <div class='grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 '>
                    <div class='mr-auto lg:mr-8 place-self-center lg:col-span-7 '>
                        <h1 class='z-10 block mb-4 text-3xl font-extrabold mt-44 md:mt-0 tracking-tight leading-none  sm:text-4xl md:text-5xl xl:text-6xl text-[#A48927] '>
                            Welcome to The Great Solana Stress Test
                        </h1>
                        <p class='z-10 block md:max-w-2xl mb-3 font-light text-sm sm:text-base lg:mb-6 md:text-lg lg:text-xl text-gray-400'>
                            Make sure to read the white paper on medium for a
                            better understanding of this experiment. Also make
                            sure to read and agree to the disclaimer which will
                            pop-up before connecting to this dApp. <br />
                            Join our{' '}
                            <span className='font-bold'>one and only</span>{' '}
                            Telegram and X/Twitter as listed below to stay up to date. <br />
                            Also feel free to engage using{' '}
                            <span className='font-bold'> $TSST</span>{' '}
                            <span className='font-bold'> #SolOnFomo</span>{' '}
                            <span className='font-bold'>
                                {' '}
                                #TheSolanaStressTest{' '}
                            </span>{' '}
                            <br />
                            You can <span className='font-bold'>
                                {' '}
                                only
                            </span>{' '}
                            participate in this experiment by connecting your
                            wallet{' '}
                            <span className='font-bold'>
                                exclusively through your Phantom wallet browser.
                            </span>
                        </p>
                        <div className='flex text-white items-center justify-between md:justify-normal   md:gap-12'>
                            <a
                                href='https://thesolanastresstest.medium.com/'
                                target='_blank'
                                rel='noreferrer'
                                className='flex flex-col items-center justify-center gap-2 cursor-pointer'
                            >
                                <FaMedium className='text-white text-3xl' />

                                <button className='hidden md:flex bg-slate-700/50 rounded-md  px-4 py-0.5 '>
                                    Read me ...
                                </button>
                            </a>
                            <a
                                href='https://t.me/thesolanastresstest'
                                target='_blank'
                                rel='noreferrer'
                                className='flex flex-col items-center justify-center gap-2 cursor-pointer  '
                            >
                                <BiLogoTelegram className='text-white text-3xl' />
                                <button className=' hidden md:flex bg-slate-700/50 rounded-md  px-4 py-0.5'>
                                    Follow me...
                                </button>
                            </a>
                            <a
                                href='https://x.com/solonfomo'
                                target='_blank'
                                rel='noreferrer'
                                className='flex flex-col items-center justify-center gap-2 cursor-pointer  '
                            >
                                <FaXTwitter className='text-white text-3xl' />
                                <button className='hidden md:flex bg-slate-700/50 rounded-md  px-4 py-0.5'>
                                    Follow me...
                                </button>
                            </a>
                            <div
                                onClick={() => {
                                    setIsInstructModal(true)
                                }}
                                className='flex flex-col items-center justify-center gap-2 cursor-pointer  '
                            >
                                <FaRegEye className='text-white text-3xl' />
                                <button className='hidden md:flex bg-slate-700/50 rounded-md  px-4 py-0.5'>
                                    view
                                </button>
                            </div>
                        </div>
                        {/* <p className="text-gray-300 text-sm sm:text-base md:text-lg font-regular mb-2">
              <b>Minimum Share:</b> 0.5 SOL
            </p>
            <p className="text-gray-300 text-sm sm:text-base md:text-lg font-regular mb-2">
              <b>MC:</b> 100 SOL Deposited
            </p>
            <p className="text-gray-300 text-sm sm:text-base md:text-lg font-regular mb-2">
              <b>Reward:</b> 40 SOL
            </p> */}
                    </div>

                    <div class='mt-4 lg:mt-0 lg:col-span-5 lg:flex items-center relative'>
                        {/* <div className="w-60 h-60 bg-blue-600 rounded-full absolute top-0 right-0 bottom-0 left-0 translate-x-1/2 translate-y-1/2 blur-[130px]">
                      </div> */}
                        <div className='bg-neutral-900  w-full h-fit p-5 md:p-6 items-center z-10 rounded-xl border border-gray-800'>
                            <div className='w-auto h-auto '>
                                <div className='w-40 h-40 bg-[#B4992D] blur-[100px] rounded-full absolute top-0 right-0  translate-x-1/2 '></div>

                                <div className='w-28 h-28 bg-[#9C7CAA] rounded-full absolute top-0 right-0 bottom-0 left-0 translate-x-1/2 blur-[80px]'></div>
                                <div className='countdown mx-auto w-full flex flex-col  items-center gap-y-2'>
                                    <h1 className='text-[white] w-fit text-sm  md:text-md font-medium uppercase '>
                                        Countdown Timer
                                    </h1>
                                    <Counter
                                        startCounter={masterData?.lastDepositId}
                                        startTime={winnerTime}
                                    />
                                </div>

                                <div className='mt-5'>
                                    <div className='flex justify-between px-1 py-3.5 border-b border-gray-600'>
                                        <h1 className='text-base text-white'>
                                            Minimum deposit:
                                        </h1>
                                        <p className='text-base text-white flex items-center gap-2'>
                                            {
                                            }

                                            {(masterData?.depositAmount /
                                                LAMPORTS_PER_SOL)}

                                            SOL

                                            <span>
                                                ($
                                                {((masterData?.depositAmount /
                                                    LAMPORTS_PER_SOL) * usdBalanceInSol)?.toFixed(2)}

                                                )
                                            </span>
                                        </p>
                                    </div>

                                    <div className='flex justify-between px-1 py-3.5 border-b border-gray-600'>
                                        <h1 className='text-base text-white'>
                                            Mcap:
                                        </h1>
                                        <p className='text-base text-white flex items-center justify-center gap-2'>
                                            {(masterData
                                                ? masterData?.totalDeposited /
                                                LAMPORTS_PER_SOL
                                                : 0
                                            ).toFixed(4)}{' '}
                                            SOL

                                            <span>
                                                ($

                                                {((masterData
                                                    ? masterData?.totalDeposited /
                                                    LAMPORTS_PER_SOL
                                                    : 0
                                                ).toFixed(4) * usdBalanceInSol).toFixed(2)}
                                                )
                                            </span>
                                        </p>
                                    </div>

                                    <div className='flex justify-between px-1 py-3.5 border-b border-gray-600'>
                                        <h1 className='text-base text-white'>
                                            Reward:
                                        </h1>
                                        <p className='text-base text-white flex items-center justify-center gap-2'>
                                            {(
                                                (40 *
                                                    masterData?.totalDeposited) /
                                                100 /
                                                LAMPORTS_PER_SOL
                                            ).toFixed(4)}{' '}
                                            SOL

                                            <span>
                                                ($
                                                {((
                                                    (40 *
                                                        masterData?.totalDeposited) /
                                                    100 /
                                                    LAMPORTS_PER_SOL
                                                ).toFixed(4) * usdBalanceInSol).toFixed(2)}
                                                )
                                            </span>
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className={
                                        publicKey ? 'block' : 'hidden mt-5'
                                    }
                                >
                                    <div className='flex justify-between px-1 py-3.5 border-b border-gray-600'>
                                        <h1 className='text-base text-white'>
                                            Your share:
                                        </h1>
                                        <p className='text-base text-white flex items-center justify-center gap-2'>
                                            {yourShare
                                                ? (
                                                    yourShare /
                                                    LAMPORTS_PER_SOL
                                                ).toFixed(5)
                                                : 0}{' '}
                                            SOL
                                            <span>
                                                ($
                                                {yourShare
                                                    ? ((
                                                        yourShare /
                                                        LAMPORTS_PER_SOL
                                                    ).toFixed(5) * usdBalanceInSol).toFixed(2)
                                                    : 0}
                                                )
                                            </span>
                                        </p>
                                    </div>

                                    <div className='flex justify-between px-1 py-3.5 border-b border-gray-600'>
                                        <h1 className='text-base text-white'>
                                            Your active deposits:
                                        </h1>
                                        <p className='text-base text-white'>
                                            {noOfDeposits ? noOfDeposits : 0}
                                        </p>
                                    </div>

                                    <div className='flex justify-between px-1 py-3.5 border-b border-gray-600'>
                                        <h1 className='text-base text-white'>
                                            Claimed earnings:
                                        </h1>
                                        <p className='text-base text-white flex items-center justify-center gap-2'>
                                            {userEarnings
                                                ? (
                                                    userEarnings /
                                                    LAMPORTS_PER_SOL
                                                ).toFixed(5)
                                                : 0}{' '}
                                            SOL

                                            <span>
                                                ($
                                                {userEarnings
                                                    ? ((
                                                        userEarnings /
                                                        LAMPORTS_PER_SOL
                                                    ).toFixed(5) * usdBalanceInSol)?.toFixed(2)
                                                    : 0}
                                                )
                                            </span>
                                        </p>
                                    </div>
                                    <div className='flex justify-between px-1 py-3.5 border-b border-gray-600'>
                                        <h1 className='text-base text-white'>
                                            Claimable earnings:
                                        </h1>
                                        <p className='text-base text-white flex items-center justify-center gap-2'>
                                            {sumClaimableAmount(depData)} SOL
                                            <span>(${(sumClaimableAmount(depData) * usdBalanceInSol).toFixed(2)})</span>
                                        </p>
                                    </div>

                                    <div className='mt-4 bg-neutral-800 p-6 rounded-xl'>
                                        <p className='text-white text-sm '>
                                            {/* Your share is {averageShare < 0 ? -1 * averageShare?.toFixed(3) : averageShare?.toFixed(3)}% {averageShare < 0 ? 'less' : 'greater'} than average */}
                                            Your share is{' '}
                                            {averageShare?.toFixed(2)} % of the
                                            average share
                                        </p>
                                        <p className='text-white text-2xl font-bold my-1'>
                                            increase share to increase earnings
                                        </p>
                                        <p className='text-white text-sm '>
                                            Claim earnings or redeposit earnings
                                            to increase share
                                        </p>

                                        <DepositBtn
                                            depositAmountL={
                                                masterData?.depositAmount
                                            }
                                            winnerTime={winnerTime}
                                        />
                                        {depData?.length > 0 ? (
                                            <button
                                                type='button'
                                                onClick={() =>
                                                    setIsOpenModal(true)
                                                }
                                                class='text-white mt-4 w-full font-medium rounded-lg text-sm px-5 py-3.5 me-4 bg-[#000000] hover:bg-[#a48927e8] active:bg-[#a48927da] '
                                            >
                                                Claim
                                            </button>
                                        ) : (
                                            <></>
                                        )}

                                        {/* <button type="button" onClick={() => {setIsOpenModal(true)}} class="text-white mt-4 w-full font-medium rounded-lg text-sm px-5 py-3.5 me-4 bg-[#000000] hover:bg-[#a48927e8] active:bg-[#a48927da] ">Claim</button> */}

                                        {UserInAddData &&
                                            !isClaimAddrOneProcessing ? (
                                            <button
                                                type='button'
                                                disabled={
                                                    UserInAddData?.claimable.toString() ==
                                                    0
                                                }
                                                onClick={claimAddrOne}
                                                class='text-white mt-4 w-full font-medium rounded-lg text-sm px-5 py-3.5 me-4 bg-[#000000] hover:bg-[#a48927e8] active:bg-[#a48927da] disabled:text-gray-400 disabled:hover:bg-black'
                                            >
                                                Stakeholder's share {' '}
                                                {/* 0.26 4 */}
                                                {UserInAddData?.per}%
                                                (
                                                {UserInAddData?.claimable.toString() /
                                                    LAMPORTS_PER_SOL}{' '}
                                                SOL claimable)
                                            </button>
                                        ) : isClaimAddrOneProcessing ? (
                                            <button
                                                disabled
                                                type='button'
                                                class='py-[13px] w-full px-5 mt-4 text-sm font-medium  rounded-lg border   focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 bg-neutral-900 text-gray-400 border-gray-600 hover:text-white hover:bg-neutral-700 inline-flex justify-center items-center'
                                            >
                                                <svg
                                                    aria-hidden='true'
                                                    role='status'
                                                    class='inline w-4 h-4 me-3  animate-spin text-neutral-600'
                                                    viewBox='0 0 100 101'
                                                    fill='none'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                >
                                                    <path
                                                        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                                                        fill='currentColor'
                                                    />
                                                    <path
                                                        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                                                        fill='#C9F649'
                                                    />
                                                </svg>
                                                Loading...
                                            </button>
                                        ) : (
                                            <></>
                                        )}

                                        {isOpenModal ? <ClaimModal /> : <></>}
                                    </div>

                                    <div></div>
                                </div>
                                <div
                                    className={
                                        !publicKey
                                            ? 'flex justify-center mt-5'
                                            : 'hidden'
                                    }
                                >
                                    {publicKey ? (
                                        <WalletMultiButton
                                            className='w-full'
                                            disabled={false}
                                        />
                                    ) : (
                                        <CustomButton />
                                    )}

                                    {/* <ConnectWallBtn pubkey={pubkey}/> */}
                                </div>
                            </div>
                        </div>
                        {/* <img className="z-10" src="https://cdn-icons-png.flaticon.com/512/2091/2091665.png" alt="mockup"/> */}
                    </div>
                </div>
                {isInstructModal ? (
                    <InstructionModal
                        isInstructModal={isInstructModal}
                        setIsInstructModal={setIsInstructModal}
                    />
                ) : (
                    <></>
                )}
            </section>
        </>
    )
}
