import Header from "../components/header";
import Hero from "../components/hero";

export default function LandingPage(){
    return(
        <div className="bg-gray">
            <Header />
            <Hero />
        </div>
    )
} 