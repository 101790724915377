import { AnchorProvider } from "@project-serum/anchor";
import * as anchor from "@project-serum/anchor";

import idl from '../back/idl2.json'

import { useWallet } from "@solana/wallet-adapter-react";
import * as web3 from '@solana/web3.js';
import keys from "../config";



export const fetchMasterAccountData = async () => {
    
    const connection = new web3.Connection(web3.clusterApiUrl('devnet'), 'confirmed')
    const programId = keys.programId;
    // console.log('master Acc ==> ', programId.toString());
    
    try {
        const provider = new AnchorProvider(connection, 'confirmed');
        const program = new anchor.Program(idl, programId, provider);
        // Initialize the master account address
        // const masterAccountAddress = new anchor.web3.PublicKey(pubkey);
        
        
        const masterAccount = await program.account.master.fetch(keys.master);
        // const addAccount = await program.account.addresses.fetch('6gZtFAEGmgDYvdZF9naUaXJhE2rsFwzzNxCQEtK54wZh');
        console.log('Master Account Data:', masterAccount);
        console.log("Master Account Data2:",
       { endSec: masterAccount.endSec.toString(),
        startSec:masterAccount.startSec.toString(),
        startTime: masterAccount.startTime.toString(),
        lastDepositId: masterAccount.lastDepositId.toString(),
        referralId: masterAccount.referralId.toString(),
        depositAmount: masterAccount.depositAmount.toString(),
        totalDeposited: masterAccount.totalDeposited.toString(),
        referralAmount: masterAccount.referralAmount.toString(),
        potAmount: masterAccount.potAmount.toString(),
        addrOneAmount: masterAccount.addrOneAmount.toString(),
        owner: masterAccount.owner.toString(),
        lastDepositor: masterAccount.lastDepositor.toString(),
        winnerAnnounced: masterAccount.winnerAnnounced}
        );
        
        return {
            endSec: masterAccount.endSec.toString(),
            startSec:masterAccount.startSec.toString(),
            startTime: masterAccount.startTime.toString(),
            lastDepositId: masterAccount.lastDepositId.toString(),
            referralId: masterAccount.referralId.toString(),
            depositAmount: masterAccount.depositAmount.toString(),
            totalDeposited: masterAccount.totalDeposited.toString(),
            referralAmount: masterAccount.referralAmount.toString(),
            potAmount: masterAccount.potAmount.toString(),
            addrOneAmount: masterAccount.addrOneAmount.toString(),
            owner: masterAccount.owner.toString(),
            lastDepositor: masterAccount.lastDepositor.toString(),
            winnerAnnounced: masterAccount.winnerAnnounced
        }

    } catch (error) {
        console.error('Error fetching master account data:', error);
    }
}
