import { useEffect, useState } from "react";
import { useWalletContext } from "../context/walletContext";
import { useWallet } from "@solana/wallet-adapter-react";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import keys from "../config";
import { toast } from "react-toastify";
import { err } from "pino-std-serializers";

export default function ClaimModal() {
    // const [isOpenModal, setIsOpenModal] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [claimedId, setClaimedId] = useState();
    const { publicKey } = useWallet()

    const [filteredData, setFilteredData] = useState();

    const { isOpenModal, setIsOpenModal, depData, setClaimableDependency, claimableDpendency } = useWalletContext()

    console.log('is open modal -==>', depData);

    const updateCalimedSt = (id) => {
        const filtered_cl = depData.find(cl => cl.depositor_id == id)
        console.log('filtered Amt ==>', filtered_cl.depositor_id);
        if (depData && filtered_cl) {
            //     const filteredAmnt = depData.filter(claimable => claimable.depositor_id.includes(id));
            setClaimedId(filtered_cl);
        }
    }





    const claimRefferals = async () => {
        setIsProcessing(true)
        const apidata = {
            "amount": claimedId?.claimable_amount,
            "depositId": claimedId?.depositor_id.toString(),
            "receiver_account_address": publicKey.toString(),
            // "public_key": publicKey.toString()
        }

        console.log('claim referals:=>', claimedId?.claimable_amount, claimedId?.depositor_id,);
        const url = keys.url + '/depositor/claim';
        console.log('urls', url);

        await fetch(url, {
            method: "POST",
            credentials: 'same-origin',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(apidata)
        })
            .then(async (resp) => {
                const response = await resp.json()
                console.log('response claim ref= ==> ', response);
                setIsProcessing(false);
                setIsOpenModal(false);
                if (response.status === 200) {
                    setClaimableDependency(!claimableDpendency)
                    toast('amount claimed successfully')
                }
                if (response.status === 404) {
                    if (response?.message.includes("Amount should be greater than gas fees")) {
                        toast.error("Error Message: Amount should be greater than gas fees.")
                    } else {
                        toast.error(response?.message)
                    }
                }
            })
            .catch((err) => {
                setIsProcessing(false);
                console.log('error claim', err);
                toast(err.message)

            })
    }


    useEffect(() => {

        // const claimable_amnts = depData?.filter((el) => el.claimable_amount > 0);
        const filtered_el = depData?.filter(val => val.claimable_amount > 0);

        // setTotalClaimable(claimable_amnts)
        setFilteredData(filtered_el);


        console.log('no of objs clm ==>', filtered_el?.length);


    }, [claimableDpendency]);
    console.log("data-depData", filteredData);

    return (
        <div id="authentication-modal" tabindex="-1" aria-hidden="true" class={isOpenModal ? "flex bg-black/30 backdrop-blur-sm top-0 left-0 overflow-y-auto overflow-x-hidden fixed z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full" : "hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"}>
            <div class="relative p-4 max-w-4xl max-h-full ">
                {/* <!-- Modal content --> */}
                <div class="relative rounded-lg shadow bg-[#0a0a0a] border border-[#353A47]">
                    {/* <!-- Modal header --> */}
                    <div class="flex flex-col  items-center justify-between px-4 pt-4 md:px-5 md:pt-5 rounded-t">
                        <h3 class="text-xl font-semibold text-center w-full uppercase text-white">
                            Claim your earnings
                        </h3>
                        <p className=" mt-2 text-slate-500 px-4 text-sm italic ">Below you can see the claimable earnings in chronological deposit order. Select any one from the list in order to claim it. Earnings will be removed from the list after claiming.

                            Please note that a claimed deposit will no longer generate earnings until you redeposit. Other unclaimed deposits will continue to generate earnings. Each claim is a separate transaction and therefore each claim should be handled manually.</p>
                        <button onClick={() => setIsOpenModal(false)} type="button" class="end-2.5 text-gray-400 bg-transparent hover:bg-neutral-800 hover:text-neutral-400 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center " data-modal-hide="authentication-modal">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>
                    {/* <!-- Modal body --> */}
                    <div class="px-4 pb-4 md:px-5 md:pb-5 mt-2 w-full">
                        <div className="bg-neutral-800 w-full p-3 max-h-[500px] scrollbar scrollbar-thumb-neutral-900 scrollbar-track-neutral-800   overflow-y-auto rounded-lg flex flex-col gap-y-2">
                            {
                                filteredData?.length === 0 ? (

                                    <div className="bg-neutral-900 p-6 w-full rounded-md flex justify-center items-center">
                                        <p className="text-neutral-400">
                                            no claimable amount available
                                        </p>
                                    </div>
                                )
                                    :
                                    (


                                        <>

                                            <div>
                                                <div className="grid grid-cols-12 text-white py-2 px-2 text-[2.2vw] md:text-[1.5vw] lg:text-[1vw] italic text-medium justify-between items-center ">
                                                    <div className="col-span-1">Index</div>
                                                    <div className="col-span-3">Wallet Address</div>
                                                    <div className="col-span-2">Date</div>
                                                    <div className="col-span-3">Claim Amount</div>
                                                    <div className="col-span-3">Deposited Amount</div>
                                                </div>
                                                {filteredData?.map((cl, index) => (

                                                    <div key={index} onClick={() => updateCalimedSt(cl.depositor_id)} className={claimedId?.depositor_id === cl.depositor_id ? "bg-neutral-900 border border-[#A48927] px-2 py-5 md:p-5 rounded-md flex flex-col justify-between" : "bg-neutral-900 px-2 py-5  md:p-5 rounded-md flex-col justify-between"} >
                                                        <div className="w-full grid grid-cols-12  text-[2vw] md:text-[1.5vw] lg:text-[0.8vw]">
                                                            <p className="col-span-1 text-white">{index + 1}</p>
                                                            <p className={claimedId?.depositor_id === cl.depositor_id ? "text-neutral-400 truncate col-span-3" : "text-neutral-400 truncate col-span-3"}>
                                                                {cl.wallet_address.toString().substr(0, 15)}...
                                                            </p>
                                                            <p className={claimedId?.depositor_id === cl.depositor_id ? "text-neutral-400 truncate col-span-2" : "text-neutral-400 truncate col-span-2"}>
                                                                {cl.time.toString().substr(0, 10)}
                                                            </p>
                                                            <p className={claimedId?.depositor_id === cl.depositor_id ? "text-white font-semibold col-span-3" : "text-white font-semibold col-span-3"}>{(cl.claimable_amount / LAMPORTS_PER_SOL).toFixed(9)} SOL</p>
                                                            <p className={claimedId?.depositor_id === cl.depositor_id ? "text-white font-semibold col-span-3" : "text-white font-semibold col-span-3"}>{(cl.amount / LAMPORTS_PER_SOL).toFixed(9)} SOL</p>
                                                        </div>

                                                        <div>


                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                        </>
                                    )
                            }



                            {/* <div className="bg-neutral-900 p-6 rounded-md flex justify-between items-center">
                            <p className="text-neutral-400">
                            94Qm2Zm1RbHdX8o7cLMKgKK...
                            </p>

                            <p className="text-white font-semibold">0.192 SOL</p>
                        </div>  */}

                        </div>
                        {isProcessing ?
                            <button disabled type="button" class="py-[13px] w-full px-5 mt-4 text-sm font-medium  rounded-lg border   focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 bg-neutral-900 text-gray-400 border-gray-600 hover:text-white hover:bg-neutral-700 inline-flex justify-center items-center">
                                <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3  animate-spin text-neutral-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#C9F649" />
                                </svg>
                                Loading...
                            </button>
                            // : (totalClaimable?.length == 0) ?
                            // <button
                            // // onClick={initializeAccount}
                            // onClick={claimRefferals}
                            // disabled
                            // class="inline-flex cursor-pointer mt-4 w-full overflow-hidden text-ellipsis items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-[#A48927] hover:bg-[#a48927e8] active:bg-[#a48927da] focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900 disabled:bg-[#513724ce] disabled:text-neutral-600 disabled:cursor-default"
                            // >
                            //     Claimed
                            // </button>
                            :
                            <button
                                // onClick={initializeAccount}
                                onClick={claimRefferals}
                                disabled={claimedId != undefined ? false : true}
                                class="inline-flex cursor-pointer mt-4 w-full overflow-hidden text-ellipsis items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-[#A48927] hover:bg-[#a48927e8] active:bg-[#a48927da] focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900 disabled:bg-[#513724ce] disabled:text-neutral-600 disabled:cursor-default"
                            >
                                Claim Now
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}